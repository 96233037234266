:root {
    --main-text-color: #330d23; 
    --banner-color:  #ba826e;
    --hover-color: #d2332f; /* Same color as in the japanese flag */
    --main-white-color: #e6ddd0;

/*     --main-text-color: #574c4c; 
    --banner-color:  #fef7e5;
    --hover-color: #ED1F34; 
    --main-white-color: #fcfcfc;*/
}