@import '../../variables.css';

.signup-card {
    padding: 20px;
    text-align: center;
}

.signup-form {
    margin-bottom: -45px;
}

.signup-control {
    width: 80%;
    border: 1px solid var(--main-white-color);
    border-radius: 4px;
    text-align: center;
}

.signup-button {
    background-color: var(--banner-color);;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    padding: 10px;
    transition: background-color 0.3s ease;
    width: calc(80% + 20px);
}

.signup-button:hover {
    background-color: #811212;
}

.error-container{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.error-message {
    font-style: italic;
    color: var(--hover-color);
    text-align: center;
    width: calc(80%);
}

.text-center {
    color: white;
    margin-top: 10px;
    text-align: center;
    color: var(--banner-color);
}

.text-center a {
    color: var(--banner-color);
    text-decoration: underline;
}

.text-center a:hover {
    color: var(--banner-color);
}

.login {
    color: var(--banner-color);
    margin-top: 20px;
    text-align: center;
}

.login a {
    color: var(--banner-color);
    text-decoration: underline;
}

.login a:hover {
    color: #811212;
}