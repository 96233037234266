.blog-container {
  padding: 20px;
  text-align: center;
}

.upload-link {
  margin-bottom: 10px;
  display: block;
  text-align: center;
}

.filter-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
}

.filter-controls select {
  padding: 4px 12px;
  border: 1px solid var(--main-white-color);
  border-radius: 1px;
  font-size: 14px;
}

.blogs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
}

.blog-post-wrapper {
  position: relative; 
}

.blog-post-link {
  text-decoration: none;
  color: inherit;
  position: relative;
}

.blogpost {
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.blogpost:hover {
  transform: scale(1.02);
}

.prevImg {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.postInfo {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.title {
  font-size: 1.5em;
  margin-bottom: 0px;
  color: #333;
}

.dateContainer {
  text-align: center;
  font-size: 0.8em;
  color: #666;
  margin-top: 10px;
  margin-bottom: -5px;
}

.date, .timeStamp {
  color: #888;
}

.postDesc {
  text-align: left;
  font-size: 1em;
  line-height: 1.2em; /* Set the line-height */
  height: 3.6em; /* 3 lines = 3 * line-height */
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Allow text wrapping */
}


.newtag {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: var(--hover-color);
  color: var(--main-white-color);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 15px;
}

.place{
  margin-top: -8px;
  margin-bottom: -10px;
}