@import '../../variables.css';

.map-container {
  display: flex;
  position: relative;
  width: 100vw;
  height: calc(100vh - 85px);
}

.map-card {
  max-width: 250px;
  padding: 15px;
  border-radius: 10px;
  background: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  overflow: hidden;
}

.map-card h4 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #333;
}

.map-card label {
  font-weight: bold;
  color: #555;
  display: block;
  margin-bottom: 5px;
}

.map-card p {
  margin: 5px 0 10px;
  color: #666;
}

.map-card .info-section,
.map-card .image-section,
.map-card .tags-section,
.map-card .meta-section {
  margin-bottom: 15px;
}

.map-card .images {
  display: flex;
  gap: 10px;
}

.map-card .images img {
  width: 100%;
  max-width: 100px;
  height: auto;
  border-radius: 5px;
}

.map-card .tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.map-card .tags span {
  display: inline-block;
  background: #f0f0f0;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  color: #666;
}

.map-card .meta-section {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #999;
}

.map-card .meta-section .username b {
  color: #333;
}

.marker img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.map-card form {
  display: flex;
  flex-direction: column;
}

.map-card form div {
  margin-bottom: 10px;
}

.map-card form label {
  margin-bottom: 5px;
}

.map-card form input,
.map-card form textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: 'Arial', sans-serif;
}

.map-card form textarea {
  resize: vertical;
}

.map-card form button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.map-card form button:hover {
  background-color: #0056b3;
}

.plus-icon {
  position: fixed;
  background: var(--main-white-color);
  border: none;
  border-radius: 0 0 50% 0;
  padding: 8px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.7);
  transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
  z-index: 999;
}

.plus-icon:hover {
  background: #f0f0f0;
}

.sidebar.open + .plus-icon {
  transform: translateX(300px);
}
