@import '../../variables.css';

.login-card {
    padding: 20px;
    text-align: center;
}

.login-form {
    margin-bottom: -10px;
}

.form-group {
    margin-bottom: 15px;
    text-align: center;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-control {
    width: 80%;
    padding: 10px;
    border: 1px solid var(--main-white-color);
    border-radius: 4px;
    text-align: center;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.login-button {
    background-color: var(--banner-color);
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    padding: 10px;
    transition: background-color 0.3s ease;
    width: calc(80% + 20px);
}

.login-button:hover {
    background-color: #811212;
}

.error-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.error-message, .success-message {
    font-style: italic;
    border-radius: 4px;
    color: var(--hover-color);
    padding: 10px;
    text-align: center;
    width: 80%;
    margin-bottom: -10px;
}

.success-message {
    color: rgb(5, 117, 5);
}

.forgot {
    color: var(--banner-color);
    margin-top: -15px;
    text-align: center;
}

.forgot a {
    color: var(--banner-color);
    text-decoration: underline;
}

.forgot a:hover {
    color:#811212;
}

.signup {
    color: var(--banner-color);
    margin-top: 0;
    text-align: center;
}

.signup a {
    color: var(--banner-color);
    text-decoration: underline;
}

.signup a:hover {
    color: #811212;
}