@import '../../variables.css';

.sidebar {
    width: 300px;
    height: calc(100vh - 85px);
    background-color: var(--main-white-color);
    box-shadow: 2px 50px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 85px;
    left: 0;
    overflow-y: auto;
    z-index: 1000;
    padding: 20px;
    box-sizing: border-box;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
}

.sidebar.open {
    transform: translateX(0);
}

.sidebar-content {
    margin-top: 30px;
}

/* Label styling
.sidebar .sidebar-content label {
    font-weight: bold;
    display: block;
    margin-top: 10px;
}
 */

.sidebar .sidebar-content input,
.sidebar .sidebar-content textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: inherit;
}

.sidebar .sidebar-content button {
    margin-top: 15px;
    padding: 10px 15px;
    background-color: var(--banner-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
}

.sidebar .sidebar-content button:hover {
    background-color: #811212;
}

.button-container-2 {
    display: flex;
    width: 100%;
    height: 100%;
}
  
.button-container-2 button {
    flex: 1;
    height: 100%;
    margin-top: -10px;
}

.button-container-2 button:not(:last-child) {
    margin-right: 10px;
}

/* Marker title styling */
.sidebar .place {
    margin-top: 0;
    margin-bottom: 10px;
    color: #333;
}

.sidebar .info-section,
.sidebar .image-section,
.sidebar .tags-section {
    margin-bottom: 15px;
}

.sidebar .image-section{
    margin-bottom: 5px;
}

.sidebar .meta-section{
    margin-bottom: 0px;
}

/* Image gallery styling */
.sidebar .images {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

/* Image styling */
.sidebar .images img {
    width: 100%;
    max-width: 280px;
    height: auto;
    border-radius: 5px;
    object-fit: cover;
}

/* Tags styling, not in use*/
.sidebar .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.sidebar .tags span {
    display: inline-block;
    background: #f0f0f0;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px;
    color: #666;
}

/* Meta section styling */
.sidebar .meta-section {
    text-align: center;
    justify-content: space-between;
    font-size: 12px;
    color: #999;
}

.sidebar .meta-section .username b {
    color: #999;
}

.sidebar .image-content img {
    max-width: 100%;
    border-radius: 5px;
}

.sidebar .image-info {
    margin-top: 10px;
    text-align: center;
}

.sidebar .image-info h4 {
    margin: 5px 0;
    color: #999;
}

.sidebar .image-info p {
    margin: 5px 0;
    color: #666;
}

.sidebar-title{
    text-align: center;   
    margin-top: -40px;
    font-size: 26px;
    line-height: 1.1;
}

.filter-icon {
    width: 20px;
    height: 20px;
}

.filter-section div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 10%;
}

.filter-section input[type="checkbox"] {
    margin-right: 10px; 
    flex-shrink: 0;
}

.filter-section label {
    flex-grow: 1;
    white-space: nowrap;
    padding-left: 5px;
}

.horizontal-line {
    width: 100%;
    height: 1px;
    background-color: #000;
    margin: 10px 0;
}

.sidebar-title-2 {
    text-align: center;   
    margin-top: 0px;
    font-size: 26px;
}

.type {
    text-align: center;   
    font-style: italic;
    font-weight: normal;
    margin-top: -4px;
    font-size: 15px;
}

.tips {
    text-align: center;   
}

.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.profile-pic {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 5px;
    margin-top: -10px;
}

.username {
    text-align: center;
    font-weight: bold;
}

.recommended-by {
    text-align: center;
    justify-content: space-between;
    font-size: 14px;
    color: #999;
}

.description-textarea {
    width: 100%;
    height: 150px;
    resize: none;
    overflow-y: auto;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-family: inherit;
}

.sidebar .sidebar-content .delete-button{
    background-color: red;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 16px;
}

.sidebar .sidebar-content .delete-button:hover{
    background-color: darkred;
}

.blog-info{
    text-align: left;
    word-wrap: break-word;  /* Forces long words to break and wrap to the next line */
    white-space: normal;    /* Ensures the text wraps normally */
    overflow-wrap: break-word; /* Additional rule to handle long words */
}