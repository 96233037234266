.about{
    text-align: center;
    padding-top: 10px;
}

.about p{
    text-align: center;
    padding-left: 50px;
    padding-right: 50px;
}

.homepage-titles{
    text-align: center;
}

.carouselExcuse{
    text-align: center;
}

.spacer {
    height: 50px; /* Adjust this value for the desired amount of space */
}