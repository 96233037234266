@import '../../variables.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

.header{
    margin-top: 0px;
    font-family: 'Poppins', sans-serif;
    background-color: var(--main-white-color);
}

.headerTitles{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Noto Serif", serif;
    color: var(--banner-color);
    -webkit-text-stroke-width: 0.5px;
    text-shadow: 0px 3px 25px black;
    -webkit-text-stroke-color: black;
}

.headerTitleSm{
    position: absolute;
    top: 18%;
    font-size: 50px;

}

.headerTitleLg{
    position:absolute;
    top: 22%;
    font-size: 100px;
}

.headerImg{
    width: 100%;
    height: calc(100% - 30px);
    margin-top: 0px;
    object-fit: cover;
}