@import '../../variables.css';

.upload-images {
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.upload-images h1 {
    font-size: 2rem;
    margin-bottom: 0px;
}

.upload-images input[type="file"] {
    padding: 10px;
    border-radius: 4px;
    width: 80%;
    text-align: center;
    font-size: 1rem;
    margin-top: -20px;
}

.upload-images input[type="text"] {
    width: 80%;
    padding: 10px;
    border: 1px solid var(--main-white-color);
    border-radius: 4px;
    font-size: 1rem;
    background-color: white;
    margin-bottom: 10px;
    font-family: inherit;
}

.upload-images input.disabled-input {
    background-color: lightgray;
    cursor: not-allowed;
    color: white; /* Unsure why this doesn't change the text color */
}

.upload-images button {
    background-color: var(--banner-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    padding: 10px;
    width: calc(80% + 20px);
    transition: background-color 0.3s ease;
}

.upload-images button:hover {
    background-color: #811212;
}

.upload-images button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

.optional-note {
    margin-bottom: 10px;
}