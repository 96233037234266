@import '../../variables.css';

.comments-section {
  margin: 20px;
}

.comments-section h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: center;
  margin-top: -10px;
}

.comments-section .login-prompt {
  margin-top: -15px;
  text-align: center;
}

.comments-section textarea {
  width: 100%;
  height: 50px; 
  padding: 10px;
  resize: none; 
  overflow-y: auto; 
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-family: inherit;
}

.comments-section button {
    padding: 10px 15px;
    background-color: var(--banner-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    margin-top: -10px;
}

.comments-section button:hover {
    background-color: #811212;
}


.comment {
  border-bottom: 1px solid #bdbdbd;
  padding: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.comment .user-info {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.comment .user-info img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.reply {
  margin-top: 10px;
  border-left: 2px solid #9b2828;
  padding-left: 10px;
}

.reply .user-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.reply .user-info img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.reply p {
  margin: 5px 0;
  text-align: left;
  margin-top: -10px;
}

.reply-button{
    margin-top: -10px;
}

.comment-text{
    margin-bottom: 10px;
}