.blog-posts-carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 1;
}

.carousel-control-prev,
.carousel-control-next {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.blog-container {
  display: flex;
  transition: transform 0.5s ease;
}

.blog-container .blogpost {
  flex: 1 0 50%; /* Display two blog posts per page */
  margin: 10px;
  background-color: #f9f9f9;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.blog-container .blogpost:hover {
  transform: scale(1.05);
}

.blogpost img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blogpost .postInfo {
  padding: 15px;
}

.blogpost .title {
  font-size: 1.2em;
  margin-bottom: 5px;
  color: #333;
}

.blogpost .dateContainer {
  font-size: 0.9em;
  color: #666;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.blogpost .date, .blogpost .timeStamp {
  color: #888;
}

.blogpost .postDesc {
  font-size: 1em;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.newtag {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: var(--hover-color);
  color: var(--main-white-color);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}
