.blogpost {
  width: calc(50% - 55px);
  margin: 0 25px 40px;
  cursor: pointer;
  background-color: rgb(228, 178, 228);
  border-radius: 20px;
  overflow: hidden;
}

.prevImg {
  width: 100%;
  height: 280px;
  object-fit: cover;
  border-radius: 20px;
}

.postInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 40px;
  font-weight: 700;
  margin-top: 5px;
}

.place {
  margin-top: -10px;
  color: rgb(156, 0, 0);
  display: flex;
  align-items: center;
}

.dateContainer {
  line-height: 1.2;
  text-align: center;
}

.date, .timeStamp {
  color: #988;
}

.timeStamp {
  font-size: 15px;
}

.postDesc {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.blog-post-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}