@import '../../variables.css';

.gallery {
    padding: 20px;
    text-align: center;
}

.upload-link {
    margin-bottom: 10px;
    display: block;
    text-align: center;
}

.filter-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
}

.filter-controls label {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.filter-controls input[type="checkbox"] {
    margin-right: 10px;
}

.filter-controls select {
    padding: 4px 12px;
    border: 1px solid var(--main-white-color);
    border-radius: 1px;
    font-size: 14px;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

.image-item {
    position: relative;
    cursor: pointer;
}

.image-item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

.image-item img:hover {
    transform: scale(1.05);
}

.image-modal {
    position: fixed;
    top: 85px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-modal-content {
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    background-color: var(--main-white-color);
    padding: 20px;
    border-radius: 8px;
}

.image-modal-content img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.image-modal-content p {
    margin-top: 0px;
}

.new-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: var(--hover-color);
    color: var(--main-white-color);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
}

.delete-button {
    background-color: red;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 16px;
}

.delete-button:hover {
    background-color: darkred;
}

.horizontal-line {
    width: 100%;
    height: 1px;
    background-color: #000;
    margin: 10px 0;
}