@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import '../../variables.css';

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: var(--main-white-color);
}

.container {
    max-width: 5000px;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    z-index: 100;
}

.navbar {
    height: 85px;
    background-color: var(--banner-color);
    position: fixed;
    top: 0; /* Ensures it stays at the top */
    width: 100%;
    z-index: 1000;
}

.logo {
    margin-top: 5px;
    display: flex;
    align-items: center;
    z-index: 9999;
}

.logo-text {
    font-size: 19px;
    line-height: 1.2;
    text-align: center;
    margin-left: 10px;
}

.logo-link {
    text-decoration: none; 
    color: inherit; 
}

.navButton {
    font-size: 19px;
    font-weight: 400;
    color: var(--main-text-color);
    background-color: transparent;
    border: none;
    cursor: pointer; 
    padding: 0;
    transition: color 0.3s ease;
}
  
.navButton:hover {
    text-decoration: none;
    color: var(--hover-color);
}

.ProfilePic{
    width: 55px;
    height: 55px;
    margin-top: 3px; 
    border-radius: 50%;
    object-fit: cover;
    justify-content: center;
    cursor: pointer;
}

.menu-icon {
    margin-right: -10px; 
    display: none;
}

.nav-elements ul a:hover {
    color: var(--hover-color);
}

.nav-elements ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    flex-grow: 1;
}

.nav-elements ul li:not(:last-child) {
    margin-right: 50px;
}

.nav-elements ul li:last-child {
    margin-right: 20px;
}

.nav-elements ul a {
    font-size: 19px;
    font-weight: 400;
    color: var(--main-text-color);
    text-decoration: none;
    display: block;
    text-align: center;
    transition: color 0.3s ease;
}

.nav-elements ul a.active {
    color: var(--main-text-color);
    font-weight: 500;
    position: relative;
    transition: color 0.3s ease;
}

.nav-elements ul a.active:hover {
    color: var(--hover-color);
}

.nav-elements ul a.active::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--main-text-color);
    transition: background-color 0.3s ease;
}

.nav-elements ul a.active:hover::after {
    background-color: var(--hover-color);
}

/* Responsive adjustments for screen widths up to 1020px */
@media (max-width: 1020px) {
    .nav-elements ul li:not(:last-child) {
        margin-right: 30px;
    }
    .nav-elements ul li:last-child {
        margin-right: 10px;
    }
}

/* Responsive adjustments for screen widths up to 890px, Becomes hamburger menu*/
@media (max-width: 890px) {
    .menu-icon {
        display: block;
        cursor: pointer;
    }

    .nav-elements {
        position: absolute;
        right: 0;
        top: 60px;
        background-color: var(--banner-color);
        width: 0px;
        height: calc(100vh - 60px);
        transition: all 0.3s ease-in;
        overflow: hidden;
    }

    .nav-elements.active {
        width: 270px;
    }

    .nav-elements ul {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .nav-elements ul li {
        margin-right: unset;
        margin-top: 22px;
        align-items: center;
    }

    .nav-elements ul li:last-child {
        margin-right: 28px;
    }
}