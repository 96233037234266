@import '../../variables.css';

.footer-container {
    background-color: var(--banner-color);
    color: var(--main-text-color);
    padding: 15px 0;
    text-align: center;
}

.contactText{
    font-size: 19px;
}

.social-icons {
    display: flex;
    justify-content: center;
    margin-top: 2px;
}

.social-icons a {
    color: var(--main-text-color);
    font-size: 26px;
    margin: 0 10px;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: var(--hover-color);
}

.nameText{
    margin-top: -2px;
    font-size: 12px;
    line-height: 1.1;
}