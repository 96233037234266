@import '../../variables.css';

.profile-div {
    padding: 20px;
    text-align: center;
}

.profile-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.ppic {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 15px;
    border: 2px solid var(--banner-color);
}

.profile-controls {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-top: -5px;
}

.profile-controls input[type="file"] {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid var(--main-white-color);
    border-radius: 4px;
    width: 80%;
    text-align: center;
}

.profile-controls button {
    background-color: var(--banner-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    padding: 10px;
    width: calc(80% + 20px);
    transition: background-color 0.3s ease;
}

.profile-controls button:hover {
    background-color: #811212;
}

.profile-controls button:disabled {
    background-color: lightgray;
    cursor: not-allowed;
}

.delete-btn {
    background-color: #ff4c4c;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

.username-section {
    margin-top: 20px;
    text-align: center;
}

.username-section input[type="text"] {
    width: 80%;
    padding: 10px;
    border: 1px solid var(--main-white-color);
    border-radius: 4px;
    text-align: center;
    margin-bottom: 5px;
}

.username-section button {
    background-color: var(--banner-color);
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    padding: 10px;
    width: calc(80% + 20px);
    transition: background-color 0.3s ease;
    margin-top: 0;
}

.username-section button:hover {
    background-color: #811212;
}

.username-section button:disabled {
    background-color: lightgray;
    cursor: not-allowed;
}

.taken {
    border: 2px solid red;
}

.error {
    font-style: italic;
    color: red;
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
}
