.blogpage {
  padding: 20px;
  text-align: center;
}

.horizontal-line {
  width: 100%;
  height: 1px;
  background-color: #000;
  margin: 10px 0;
}

.delete-button {
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 16px;
}

.delete-button:hover {
  background-color: darkred;
}

.blogpage-images {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap; /* Allows the images to wrap to the next line */
  gap: 10px; /* Space between images */
  justify-content: center;
}

.blogpage-images img {
  max-width: 45%;  /* Adjust the width as needed */
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.blogpage-images img:hover {
  transform: scale(1.03);
}


.blogbody {
  text-align: left;
  word-wrap: break-word;  /* Forces long words to break and wrap to the next line */
  white-space: normal;    /* Ensures the text wraps normally */
  overflow-wrap: break-word; /* Additional rule to handle long words */
}

.place-link{
  margin-bottom: 10px;
}