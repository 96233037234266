@import '../../variables.css';

.newblog-container {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.titleNewBlog {
  margin-bottom: 0px;
  text-align: center;
}

.helpNewBlog {
  margin-bottom: 10px;
  text-align: center;
}

.newblog-container form {
  display: flex;
  flex-direction: column;
}

.newblog-container label {
  font-weight: bold;
  margin-bottom: 5px;
}

.newblog-container input[type="text"],
.newblog-container textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--main-white-color);
  border-radius: 4px;
  font-size: 1rem;
  background-color: white;
  margin-bottom: 10px;
  font-family: inherit;
  box-sizing: border-box;
}

.newblog-container textarea {
  resize: vertical;
  min-height: 100px;
}

.newblog-container input[type="file"] {
  margin-bottom: 15px;
}

.newblog-container button {
  width: 100%;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: var(--banner-color);
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.newblog-container button:hover {
  background-color: #811212;
}

.newblog-container button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.addphoto-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.preview-photoitem {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.preview-photoitem img {
  width: 100px;
  height: auto;
  margin-right: 10px;
}

.preview-photoitem button {
  margin-left: 5px;
  font-size: 14px;
}

.submit-button{
  margin-top: 10px;
}

.newblog-container input.disabled-input {
  background-color: lightgray;
  cursor: not-allowed;
  color: white; /* Unsure why this doesn't change the text color */
}

/* ReactQuill editor customization */
.ql-container {
  height: auto !important; /* Adjust height */
  min-height: 200px; /* Minimum height of the editor */
  border: 1px solid var(--main-white-color);
  background-color: white;
  border-radius: 4px;
  font-family: inherit;
}

.ql-editor {
  min-height: 150px;
  font-size: 1rem;
  font-family: inherit;
  padding: 10px;
  background-color: white;
  border-radius: 4px;
  text-align: left !important;
}

.ql-toolbar {
  border: 1px solid var(--main-white-color);
  background-color: white;
}

.ql-editor.ql-blank::before {
  font-style: normal; /* Ensure consistency with placeholders */
  color: lightgray;
}